<template>
    <div class="comp-editor">
        <div class="add-box">
            <textarea placeholder="댓글을 남겨주세요." v-model="comp.comment"></textarea>
            <button class="btn2" @click="comp.showEmoticons()"></button>
            <div v-if="comp.isShowEmoticons==true" class="emo-wrap">
                <ul class="emo-box">
                    <template v-for="(irow, i) in comp.emoticons" :key="i">
                    <li @click="comp.setEmoticon(irow.code)"><img :src="irow.url"></li>
                    </template>
                </ul>
            </div>
            <button v-if="comp.emode=='E'" class="btn type1" @click="comp.doModify()">수정</button>
            <button v-else class="btn type1" @click="comp.doSubmit()">등록</button>
        </div>
    </div>
</template>

<script>
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'

export default {
    name: 'CarrotCmtEditor',
    props: {
        modelValue: {
            type: [Number, String],
            default: 0,
        },
        emode: {
            type: String,
            default: "",
        },
        ctype: {
            type: String,
            default: "",
        },
        parent: {
            type: [Number, String],
            default: 0,
        }
    },
    emits: [ 'input', 'update:modelValue', 'update:cnt' ],
    setup(props, { emit }) {
        const toast = useToast()

        const comp = reactive({
            emoticons  : [],
            isShowEmoticons : false,

            ctype      : "",
            emode      : "",
            idx_parent : 0,
            idx_bbs    : 0,
            idx        : 0,
            comment    : "",
            smile      : "",

            showEmoticons : () => {
                if( comp.isShowEmoticons == true ){
                    comp.isShowEmoticons = false;
                } else {
                    comp.isShowEmoticons = true;
                }
            },

            hideEmoticons : () => {
                comp.isShowEmoticons = false;
            },

            setEmoticon : (code) => {
                comp.comment += '' + code;
                comp.hideEmoticons();
            },

            doSearchEmoticons : () => {
                let params = {
                    ctype : comp.ctype
                };
                axios.get("/rest/component/getEmoticonList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.emoticons = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            doSearch : () => {
                let params = {
                    idx   : comp.idx,
                    ctype : comp.ctype,
                };
                axios.get("/rest/component/commentInfo", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.idx_bbs    = res.data.idx_bbs;
                        comp.idx_parent = res.data.idx_parent;
                        comp.comment    = res.data.content;
                        comp.smile      = res.data.smile;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doSubmit: () => {
                let params = {
                    ctype      : comp.ctype,
                    idx_bbs    : comp.idx_bbs,
                    smile      : comp.idx_parent>0?comp.smile:comp.smile,
                    comment    : comp.idx_parent>0?comp.comment:comp.comment,
                    idx_parent : comp.idx_parent
                };
                axios.post("/rest/component/commentAdd", params).then((res) => {
                    if( res.data.err == 0 ){
                        emit('input', true);
                        // emit('update:modelValue', comp.idx);
                        // emit('update:parent'    , comp.idx_parent);
                        emit('update:cnt'       , res.data.cnt_cmt);

                        comp.comment = "";
                        comp.smile   = "";
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            doModify: () => {
                let params = {
                    ctype      : comp.ctype,
                    idx        : comp.idx,
                    smile      : comp.smile,
                    comment    : comp.comment
                };
                axios.post("/rest/component/commentMod", params).then((res) => {
                    if( res.data.err == 0 ){
                        emit('input', true);
                        comp.comment = "";
                        comp.smile   = "";
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

        });

        onMounted(() => {
            // Mounted
            comp.doSearchEmoticons();

            comp.ctype      = props.ctype?props.ctype:"";
            comp.idx_bbs    = props.modelValue;
            comp.idx_parent = props.parent?props.parent:0;

            comp.emode = props.emode?props.emode:"";
            if( comp.emode == "E" ){
                comp.idx = props.modelValue;
                comp.doSearch();
            }
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
.comp-editor {
    display:block; width:100%; height:100%; min-height:50px;
    .add-box {
        height:100%;
        textarea {
            height:100%; min-height:50px;
        }
    }
    .emo-wrap {
        position:absolute; right:90px; bottom:55px; width:200px; height:200px; overflow:hidden; overflow-y:auto; border:1px solid #dfdfdf; border-right:0; background-color:#fff;
        .emo-box {
            >li {
                display:inline-block; padding:5px;
                img {
                    width:48px; max-height:48px; cursor:pointer;
                }
            }
        }
    }
}
</style>