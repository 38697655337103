<template>
    <div class="comp-layer comp-cmt-list">
        <div class="list-box">
            <div class="reply" v-for="(irow, i) in comp.list" :key="i" :class="comp.bindDetph(irow.depth)">
                <div class="list">
                    <div class="photobox">
                        <img class="my-profile" :src="irow.photoimg" :alt="irow.ename + '\'s profile'">
                    </div>
                    <div class="textbox">
                        <div class="nameDay">
                            <span class="name" v-if="irow.kname">{{ irow.ename }}({{ irow.kname }})</span>
                            <span class="day">{{ irow.wdate }}</span>
                        </div>
                        <div class="text preline" v-html="irow.html"></div>
                        <div class="pt-15" v-if="comp.emode=='A'&&comp.selected==irow.idx">
                            <carrot-cmt-editor emode="A" :ctype="comp.ctype" :parent="irow.idx" v-model="comp.idx" @input="comp.hideAddCmt" class="h-150px"></carrot-cmt-editor>
                        </div>
                        <div class="pt-15" v-if="comp.emode=='E'&&comp.selected==irow.idx">
                            <carrot-cmt-editor emode="E" :ctype="comp.ctype" v-model="irow.idx" @input="comp.hideModCmt" class="h-150px"></carrot-cmt-editor>
                        </div>
                    </div>
                    <div class="btnbox" v-if="irow.depth<5">
                        <!-- <button v-if="irow.is_writer=='N'" class="btn-add" @click="comp.showAddCmt(irow.idx)">댓글달기</button> -->
                        <button v-if="irow.is_writer=='Y'" class="btn-edit" @click="comp.showModCmt(irow.idx)">수정</button>
                        <button v-if="irow.is_writer=='Y'" class="btn-del" @click="comp.delCmt(irow.idx)">삭제</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="comp.isLoading" class="carrot-loader"><div class="carrot-ring"><div></div><div></div><div></div><div></div></div></div>
    </div>
</template>

<script>
import { onMounted, reactive, watch } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotCmtEditor from '@/components/common/CarrotCmtEditor.vue';
import Swal from 'sweetalert2';

export default {
    name: 'CarrotCmtList',
    components: {
        CarrotCmtEditor,
    },
    props: {
        index: {
            type: [Number, String],
            default: 0,
        },
        ctype: {
            type: String,
            default: "",
        },
        cnt: {
            type: [Number, String],
            default: 0,
        },
        reload: {
            type: Boolean,
            default: false
        }
    },
    emits: [ 'update:cnt' ],
    setup(props, { emit }) {
        const toast = useToast()

        const comp = reactive({
            emoticons : [],
            isLoading : false,

            ctype : "",
            idx   : 0,
            page  : 1,
            rows  : 1000,
            cnt   : 0,

            list : [], total : 0,

            emode    : "",
            selected : 0,

            showEmoticons : () => {
                comp.isShowEmoticons = true;
            },

            hideEmoticons : () => {
                comp.isShowEmoticons = false;
            },

            setEmoticon : (code) => {
                comp.comment += ' ' + code;
                comp.hideEmoticons();
            },

            doSearchEmoticons : () => {
                let params = {};
                axios.get("/rest/component/getEmoticonList", { params : params}).then((res) => {
                    if( res.data.err == 0 ){
                        comp.emoticons = res.data.list;
                    } else {
                        console.info(res);
                    }
                });
            },

            bindDetph: (d) => {
                return 'd-' + d;
            },

            doSearch : () => {
                let params = {
                    idx   : comp.idx,
                    page  : comp.page,
                    rows  : comp.rows,
                    ctype : comp.ctype,
                };
                comp.isLoading = true;
                axios.get("/rest/component/commentList", { params: params }).then((res) => {
                    if( res.data.err == 0 ){
                        comp.list  = res.data.list;
                        comp.total = res.data.total;

                        setTimeout(() => {
                            comp.isLoading = false;
                        }, 200);
                        emit('update:cnt', res.data.total);
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            showAddCmt: (idx) => {
                comp.emode    = "A";
                comp.selected = idx;
            },
            hideAddCmt: () => {
                comp.emode    = "";
                comp.selected = 0;
                comp.doSearch();
            },

            showModCmt: (idx) => {
                comp.emode    = "E";
                comp.selected = idx;
            },
            hideModCmt: () => {
                comp.emode    = "";
                comp.selected = 0;
                comp.doSearch();
            },

            delCmt: (idx) => {
                Swal.fire({
                    title : '댓글',
                    text  : '삭제하시겠습니까?',
                    showCancelButton : true
                }).then((res) => {
                    if( res.isConfirmed == true ){
                        let params = {
                            ctype : comp.ctype,
                            idx   : idx
                        };
                        axios.post("/rest/component/commentDel", params).then((res) => {
                            if( res.data.err == 0 ){
                                toast.success("삭제하였습니다.");
                                comp.doSearch();
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });
            }
        });

        watch(() => props.reload, (nVal) => {
            if(nVal == true && comp.idx > 0 ) {
                comp.doSearch();
            }
        });

        onMounted(() => {
            // Mounted
            comp.doSearchEmoticons();

            comp.ctype = props.ctype?props.ctype:"";
            comp.idx   = props.index;

            if( comp.idx > 0 ){
                comp.doSearch();
            }
        });

        return {comp};
   }
}
</script>
<style lang="scss" scoped>
</style>