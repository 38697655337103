<template>
    <div class="home">
        <CarrotHeader></CarrotHeader>
        <div class="sub-wrap">
            <div class="sub-wrap-title">
                <p>전자결재</p>
            </div>
            


            <!-- 서브페이지 내용 -->
            <div class="con-wrap" v-if="sales.info!=null">
                <CarrotTitle title="매출 상세"></CarrotTitle>
                <div class="board">
                    <div class="mt-40">
                        <div>
                            <table class="table-row">
                                <colgroup>
                                    <col width="180">
                                    <col width="*">
                                    <col width="180">
                                    <col width="*">
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>작성자</th>
                                        <td>
                                            {{sales.info.staff_ename}} ({{sales.info.staff_kname}})
                                        </td>
                                        <th>작성일</th>
                                        <td>
                                            {{sales.info.createdate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>구분</th>
                                        <td >
                                            {{sales.info.dept}}
                                        </td>
                                        <th>승인상태</th>
                                        <td>
                                          {{sales.info.sign_step}}
                                        </td>
                                    </tr>
                                    <tr>
                                      <th>팀장승인</th>
                                      <td>
                                        {{sales.info.idx_sign_allow_name}}
                                      </td>
                                      <th>발행법인</th>
                                      <td>
                                        {{sales.info.corp}}
                                      </td>
                                    </tr>

                                    <tr>
                                        <th>매출형태</th>
                                        <td colspan="3">
                                            {{sales.info.stype}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>매출발생일</th>
                                        <td colspan="3">
                                            {{sales.info.cdate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>회사명</th>
                                        <td>
                                            {{sales.info.company_name}}
                                        </td>
                                        <th>사업자번호</th>
                                        <td>
                                            {{sales.info.bizno}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>클래스 선택</th>
                                        <td colspan="3">
                                            <div v-for="(itm, idx) in sales.classlist" :key="idx" class="mt-5"><a class="btn-view" :href="'https://hq.carrotenglish.net/auth/common/directClassLink?uid=' + store.state.email + '&classId=' + itm.classid" target="new"
                                            >{{itm.classname}} / {{itm.tutor_ename}}({{itm.tutor_kname}}) / {{itm.hq_ename}} </a>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>내역</th>
                                        <td colspan="3">
                                            {{sales.info.detail}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>금액</th>
                                        <td>
                                            {{sales.number_format(sales.info.price)}}원 (부가세별도)
                                        </td>
                                        <th>자신의 금액<br>(금액 – RF금액)</th>
                                        <td>
                                            {{sales.number_format(sales.info.price - sales.info.rf1_cost - sales.info.rf2_cost - sales.info.rf3_cost - sales.info.rf4_cost - sales.info.rf5_cost )}}원
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>RF</th>
                                        <td colspan="3">
                                            <span v-for="(s,idx) in sales.rf" :key="idx">{{s.dept}} {{s.staff_ename}}({{s.staff_kname}}) : {{sales.number_format(s.cost)}}</span>
                                            
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>특이사항</th>
                                        <td colspan="3">
                                            {{sales.info.memo}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>첨부파일</th>
                                        <td colspan="3">
                                            <div v-if="sales.info.file1_url!=''">
                                                <a :href="sales.info.file1_url" :download="sales.info.file1_name" target="new"><span class="btn-view">{{sales.info.file1_name}}</span></a>
                                            </div>
                                            <div v-if="sales.info.file2_url!=''">
                                                <a :href="sales.info.file2_url" :download="sales.info.file2_name" target="new"><span class="btn-view">{{sales.info.file2_name}}</span></a>
                                            </div>
                                            <div v-if="sales.info.file3_url!=''">
                                                <a :href="sales.info.file3_url" :download="sales.info.file3_name" target="new"><span class="btn-view">{{sales.info.file3_name}}</span></a>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>업체 정산담당자</th>
                                        <td colspan="3">
                                            이름 : {{sales.info.clac_name}}<br>
                                            연락처 : {{sales.info.calc_tel}}<br>
                                            이메일주소 : {{sales.info.calc_email}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>발행상태</th>
                                        <td colspan="3">
                                            {{sales.info.state}}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                                <router-link to="/documents/SalesManagementPublishList"><button class="btn-default float-left mt-30">목록</button></router-link>
                                <button @click="sales.delete()" v-if="sales.auth=='Y' || sales.info.sign_step!='발행완료'" class="btn-default float-left mt-30 ml-10">삭제</button>
                                <button v-if="sales.auth=='Y' && sales.info.sign_step!='발행완료'" @click="sales.modify()" class="btn-default float-right mt-30 ml-10">수정</button>
                                <button class="btn-default float-left mt-30 ml-20" @click="sales.setSaleCheck('Y')" v-if="store.state.isSalesAdmin  && sales.info.is_check=='N'">확인요청</button>
                                <button class="btn-default float-left mt-30 ml-20" @click="sales.setSaleCheck('N')" v-if="store.state.isSalesAdmin  && sales.info.is_check=='Y'">요청취소</button>
                                <button v-if="sales.info.sign_step=='발행요청' && sales.info.idx_sign_allow == store.state.idx_staff" @click="sales.setStep('팀장승인')" class="btn-default float-right mt-30 ml-20">팀장승인</button>
                                <button v-if="sales.info.sign_step=='팀장승인' && store.state.isSalesAdmin " @click="sales.setStep('발행완료')" class="btn-default float-right mt-30 ml-20">발행승인</button>
                                <div class="clear"></div>

                            <div v-if="cmt.isLoaded==true" class="mt-40 comment-list">
                                <carrot-cmt-editor ctype="doc_sales" v-model="sales.info.idx" @input="cmt.reloadList()" class="h-150px"></carrot-cmt-editor>
                                <carrot-cmt-list :reload="cmt.isLoadCmtList" ctype="doc_sales" :index="sales.info.idx" v-model:cnt.sync="cmt.cnt_cmt"></carrot-cmt-list>
                            </div>
                                    
                        </div>
                    </div>
                </div>
            </div>
            <!-- 서브페이지 내용 끝 -->
        </div>
    </div>
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'

import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import { useRoute, useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useStore } from 'vuex'

import CarrotCmtEditor from '@/components/common/CarrotCmtEditor.vue';
import CarrotCmtList from '@/components/common/CarrotCmtList.vue';


export default {
    layout:"documents",
    components: {
        CarrotCmtList, CarrotCmtEditor
    },
    setup() {
        const toast = useToast()
        const route = useRoute();
        const router = useRouter();
        const store = useStore()

        const cmt = reactive({
            isLoaded      : false,
            isLoadCmtList : false,
            isMode   : '',
            selected : 0,

            page : 1,
            cnt_cmt : 0,
            newsmile:"", newtext:"", modsmile:"", modtext:"",
            list : [], total : 0,

            reloadList : () => {
                cmt.isLoadCmtList = false;
                setTimeout(() => {
                    cmt.isLoadCmtList = true;
                }, 100);
            }
        });

        const sales = reactive({
            // 양식 데이터
            info: null,
            rf: [],
            classlist : null,
            auth : 'N',
            setStep : (val) => {
                let params = {
                    idx : sales.info.idx,
                    value : val
                };
                axios.post('/rest/sales/setStep', params).then((res) => {
                    if( res.data.err == 0 ){
                        if(val=="발행완료" || val=="팀장승인") {
                            sales.info.sign_step = val;
                        }
                        if(val=="세금계산서승인") {
                            sales.info.sign_confirm = 'Y';
                        }
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            delete : () => {
                Swal.fire({
                    title : "매출",
                    text: '삭제하시겠습니까?',
                    showCancelButton: true,
                    confirmButtonText : "확인",
                    cancelButtonText : "취소",
                }).then((res) => {
                    if (res.isConfirmed) {
                        let params = {
                            idx : sales.info.idx
                        };
                        axios.post("/rest/sales/delSalesInfo", params).then((res) => {
                            if( res.data.err == 0 ){
                                router.push({ 
                                    name:"documents-SalesManagementPublishList"
                                });
                                return;
                            } else {
                                if(res.data.err_msg) toast.error(res.data.err_msg);
                            }
                        });
                    }
                });

            },

            modify : () => {
                router.push({ 
                    name:"documents-SalesManagementPublishModify",
                    params : { id:sales.info.idx }
                });
            },

            setSaleCheck : (value) => {
                let params = {
                    idx : sales.info.idx,
                    val : value
                };
                axios.post("/rest/sales/setSalesCheck", params).then((res) => {
                    if( res.data.err == 0 ){
                        sales.info.is_check = value;
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                });
            },

            getSalesInfo : (idx) => {
                let params = {
                    idx : idx
                };
                axios.get('/rest/sales/getSalesInfo', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        sales.classlist = JSON.parse(res.data.info.list_class);
                        sales.info = res.data.info;
                        sales.rf = res.data.rf;
                        sales.auth = res.data.auth;
                        if(store.state.isSalesAdmin) sales.auth = 'Y';
                        cmt.isLoaded = true;
                        cmt.reloadList();
                    } else {
                        if(res.data.err==201) {
                            router.push({ 
                                name:"documents-SalesManagementPublishList"
                            });
                            return;
                        } else {
                            if(res.data.err_msg) toast.error(res.data.err_msg);
                        }
                    }
                });
            },
            number_format: (num) => { 
                let price = num.toString().replace(/,/g, "");
                price = parseFloat(price);
                if(isNaN(price)) {
                    price = 0;
                }
                return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
        });
        onMounted(() => {
            // Mounted
            sales.getSalesInfo(route.params.id);
        });

        return {sales, cmt, store};
    }
}
</script>


<style lang="scss" scoped>
</style>